import axios from 'axios';
axios.defaults.withCredentials = true;

const request = () => {
  const authToken = localStorage.getItem("authToken");

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Store-Origin': process.env.REACT_APP_HASH_STORE,
      ...(authToken ? { Authorization: ("Bearer " + authToken) } : {})
    }
  })
};
export default request
