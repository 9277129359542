import React, { createContext, useState } from "react";
import Menu from "../shared/navigation/Menu";
import Navbar from "../shared/navigation/Navbar";
import Footer from "../shared/navigation/Footer";
import { Container } from "reactstrap";

export const NavigationContext = createContext();

const NavigationWrapper = ({ children }) => {
  const [isOpenMenu, setOpenMenu] = useState()

  const toggleMenu = () => setOpenMenu(!isOpenMenu)
  return (
    <NavigationContext.Provider value={{ isOpenMenu, toggleMenu, setOpenMenu }}>
      <Container fluid className="p-0 m-0 min-vh-100 position-relative">
        <Navbar/>
        <Menu/>
        {children}
      </Container>
      <Footer/>
    </NavigationContext.Provider>
  );
}


export default NavigationWrapper;