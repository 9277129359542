import React from 'react';
import contactImg from "../../../media/walls/contact.jpg";
import { Col, Row } from "reactstrap";
import DivImg from "../../shared/decoration/DivImg";
import stores from "./stores";

const Info = () => {

  return (
    <Row className="align-items-center">
      <Col xs={12} md={7}>
        <Row className="h-100 center bg-secondary-90">
          <div className="text-center p-5">
            <h2 className="title-md mb-3">MÁS INFORMACIÓN</h2>
            {stores.filter(({ showOnInfo }) => showOnInfo).map(({ small, phone }, index) =>
              <p key={index} className="common-text-sm">{small}: {phone}</p>)}
            <p>Puede solicitar más información por teléfono o a traves de nuestro formulario</p>
          </div>
        </Row>
      </Col>
      <DivImg img={contactImg} className="d-none d-md-block col-5" style={{ height: 450 }}/>
    </Row>
  );
};

export default Info;
