import React from 'react';
import { Button, Container, Row } from "reactstrap";
import Banner from "../../shared/decoration/Banner";
import { NavLink } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <Container fluid>
      <Row>
        <Banner title="Pago cancelado"/>
      </Row>
      <Row className="flex-column center text-center title-sm">
        <div style={{ zIndex: 110 }} className="text-center">
          ¡Ups!
          <br/>
          Su prceso de pago ha sido interrumpido, intentelo más tarde
          <NavLink to={"/"}>
            <Button color="secondary" size="lg">
              Inicio
            </Button>
          </NavLink>
        </div>
      </Row>
    </Container>
  );
};

export default PaymentSuccess;
