import React from "react";
import { Col, Container, Row } from "reactstrap";
import wall from "./../../media/walls/aboutUs.jpg"
import DivImg from "../shared/decoration/DivImg";
import Banner from "../shared/decoration/Banner";

const AboutUs = () => {
  return (
    <DivImg img={wall} className="container-fluid common-text">
      <Row>
      <Banner title="ABOUT US"/>
      </Row>
      <Row className="min-vh-100 center p-2 p-md-5">
        <Container className="bg-secondary-90">
          <Row>
            <Col xs={12} lg={6} className="p-3 p-md-5">
              <p>
                BAY COFFEE ROASTERS -grupo que aúna las marcas Cafès Bay, Arabay Coffee y Cafés Albertí- es líder
                nacional en ventas de café de especialidad .
              </p>
              <p>
                Un origen que se remonta a 1952 bajo el desaparecido nombre comercial de Cafés Bahía (hoy Cafès Bay),
                ofreciendo desde entonces el mejor café recién tostado. Se amplió el grupo en 2014 con la compra de la
                histórica Cafés Albertí (fundada en 1977) haciendo pervivir así una de las míticas marcas isleñas de
                café.
                Desde 2016, los cafés arábicos y los specialty coffee de Cafès Bay se engloban bajo la marca Arabay, que
                se
                ha fortalecido con la apertura de tiendas especializadas en Palma, Ibiza y Madrid, verdaderos templos
                del
                specialty coffee, que preceden a las nuevas tiendas que el grupo inaugurará en breve en Andorra, Italia,
                Emiratos Árabes Unidos y Singapore.
              </p>
            </Col>
            <Col xs={12} lg={6} className="p-3 p-md-5">
              <p>
                Nuestros cafés cuentan con certificaciones que confieren un carácter sostenible medioambientalmente a
                todo
                lo que ofrecemos:
              </p>
              <p>
                Certificado Ecológico de la UE 100% orgánicos, que garantiza al consumidor que está comprando productos
                libres de cualquier componente químico y pesticidas, garantía de transparencia ante el consumidor que
                asegura el cumplimiento de la normativa que establece la Unión Europea para la producción.
              </p>
              <p>
                Contamos con el certificado Rainforest Alliance Certified™, que asegura el uso restringido de
                plaguicidas
                químicos, la ausencia de organismos genéticamente modificados, así como un uso responsable de los
                recursos
                naturales.
              </p>
              <p>
                Por último, nuestros productos vienen distinguidos con la certificación de comercio justo Fairtrade, que
                apoya, en el ámbito del comercio internacional, a pequeños productores y productoras, así como
                trabajadores
                y trabajadoras en países en vías de desarrollo
              </p>
            </Col>
          </Row>
        </Container>
      </Row>
    </DivImg>
  );
}

export default AboutUs;