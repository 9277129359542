import React from 'react';
import ContactForm from "../shared/contact/ContactForm";
import Place from "./contact/Place";
import Info from "./contact/Info";
import { Container, Row } from "reactstrap";
import Banner from "../shared/decoration/Banner";
import wall from "./../../media/walls/fallingCoffee.jpg"
import DivImg from "../shared/decoration/DivImg";

const Contact = () => {

  return (
    <Container fluid>
      <Row>
        <Banner title={"CONTACTO"}/>
      </Row>
      <DivImg className="row justify-content-center py-2 py-md-5 px-2" img={wall}>
        <Container>
          <Place/>
          <Info/>
        </Container>
      </DivImg>
      <Row className="justify-content-center bg-primary">
        <Container>
          <ContactForm section={"CONTACT"}/>
        </Container>
      </Row>
    </Container>
  );
};

export default Contact;
