import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const Add = ({ onClick, size = "large", num = "" }) => {
  return (
    <Fab variant={num > 0 ? "extended" : "round"} size={size} onClick={onClick}>
      {num > 0 && <div className="mx-3" style={{ fontSize: "20px" }}>{num}</div>}
      <AddIcon/>
    </Fab>
  );
};

export default Add;
