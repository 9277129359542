import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CookiesModal from "../modal/CookiesModal";
import { ModalContext } from "../../wrappers/ModalWrapper";

const Cookies = () => {
  const { pathname } = useLocation();
  const { changeModalContent } = useContext(ModalContext)
  const [check, setCheck] = useState(!!localStorage.getItem("cookies-arabay"));

  useEffect(() => {
    if (!check && pathname !== "/cookies") {
      console.log("true")
      changeModalContent(<CookiesModal accept={() => {
        localStorage.setItem("cookies-arabay", "true")
        setCheck(true)
      }}/>)
    } else {
      changeModalContent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check, pathname]);

  return null;
};
export default Cookies;
