import React, { PureComponent, useContext } from 'react';
import EmptyCart from "./modalCart/EmptyCart";
import ProductCart from "./modalCart/ProductCart";
import LoginAndSignUp from "./modalCart/LoginAndSignUp";
import SelectAddress from "./modalCart/SelectAddress";
import SelectedMethod from "./modalCart/SelectedMethod";
import { toast } from "react-toastify";
import SelectedStore from "./modalCart/SelectedStore";
import Finish from "./modalCart/Finish";
import { CartContext } from "../../wrappers/CartWrapper";
import { BookingContext } from "../../wrappers/BookingWrapper";
import { makePerchuse, selectedMethod } from "../../../api/apiRequest";

const PK_LIVE = process.env.REACT_APP_STRIPE_PK;
const stripe = window.Stripe(PK_LIVE);

class ModalCart extends PureComponent {

  state = {
    productOpen: null,
    isLogged: false,
    methods: null,
    errorMethods: false,
    finish: false,
    extraPrice: null,
    refresh: 0
  };

  getMethods = (incrementPrice = 0) => {
    const { total, booking } = this.props;
    const { shipping_address } = booking.booking;
    selectedMethod({ address_name: shipping_address, price: (total + incrementPrice) })
      .then((res) => {
        this.setState({ methods: res.data.message })
      })
      .catch(() => {
        toast.error("¡Ups! Parece que nos hemos topado con un problema");
        this.setState({ error: true })
      })
  };

  openProduct = (some) => {
    this.setState({ productOpen: some })
  };

  doLogin = () => {
    this.setState({ isLogged: true })
  };

  selectAddress = (address) => {
    console.log(address)
    const { booking } = this.props;
    booking.change("shipping_address", address.name)
    booking.change("billing_address", address.name)
    this.setState({ refresh: (this.state.refresh + 1) })
  };

  buy = () => {
    const { booking, cartItems } = this.props;
    const booki = booking.booking;
    cartItems.forEach(item => {
      booki.items.push({
        item: (item.ref_code || item.item_code),
        qty: item.quantity,
        item_description: (item.item_description || "")
      })
    });
    makePerchuse(booki).then((res) => {
      try {
        stripe.redirectToCheckout({ sessionId: res.data.message }).then(function (result) {
        });
      } catch (e) {
        toast(("¡Ups! No se ha apodido realizar la redirección al formulario de pago"), {
          className: "bg-danger text-white",
          containerId: "store"
        });
      }
      booki.items.length = 0;
    })
      .catch(() => {
        toast(("¡Ups! Parece que nos hemos topado con un problema al realizar el pedido"), {
          className: "bg-danger text-white",
          containerId: "store"
        });
        booki.items.length = 0;
      });
  };

  deleteAddress = () => {
    const { booking } = this.props;
    booking.change("shipping_address", "");
    booking.change("billing_address", "")
  };

  selectMethod = (method) => {
    console.log(method);
    const { booking } = this.props;
    booking.change("shipping_rule", method.name);
    if (method.name !== "Recoger en Tienda") {
      this.setState({
        finish: true,
        extraPrice: ((method.price && method.price > 0) ? { increment: method.price, reason: method.name } : null)
      })
    }
  };

  selectStore = (store) => {
    const { booking } = this.props;
    booking.change("pickup_location", store.name);
    this.setState({ finish: true })
  };

  render() {
    const { cartItems, total, dispatch, booking } = this.props;

    console.log(this.props)
    console.log(cartItems)
    const { productOpen, isLogged, methods, errorMethods, finish, extraPrice } = this.state;
    const { shipping_address, shipping_rule } = booking.booking;
    return (
      <div className="bg-white container-fluid"
           style={{ minWidth: "290px", width: "90vw", maxWidth: "1500px", minHeight: "400px" }}>
        {cartItems <= 0 ?
          <EmptyCart/>
          :
          <div className="row h-100">
            {!finish && <div className="col-12 col-lg-8 bg-primary order-1 order-lg-0 py-4 border border-white">
              {/*<FormPurchase/>*/}
              {!isLogged && <LoginAndSignUp doLogin={this.doLogin}/>}
              {(isLogged && !shipping_address) && <SelectAddress selectAddress={this.selectAddress}/>}
              {(isLogged && shipping_address) &&
              <SelectedMethod selectMethod={this.selectMethod} methods={methods} errorMethods={errorMethods}
                              getMethods={this.getMethods}/>}
              {(isLogged && shipping_address && (shipping_rule === "Recoger en Tienda")) &&
              <SelectedStore selectStore={this.selectStore} methods={methods} errorMethods={errorMethods}/>}
            </div>}
            {finish && <div className="col-12 col-lg-8 bg-skin order-1 order-lg-0 py-4 border border-white">
              <Finish finish={this.buy}/>
            </div>}

            <div className="col-12 col-lg-4 text-dark order-0 order-lg-1">
              {cartItems.map((item, key) =>
                <ProductCart onChange={(isLogged && shipping_address) ? this.getMethods : () => {
                }} key={key} product={item} isOpen={productOpen === item.item_code} dispatch={dispatch}
                             open={() => this.openProduct(item.item_code !== productOpen ? item.item_code : null)}/>)
              }
              <div style={{ fontSize: "20px", fontWeight: 500 }} className="d-flex justify-content-between mt-3">
                <div>TOTAL {extraPrice && ("( + " + extraPrice.reason + ")")}</div>
                {extraPrice ? (total + extraPrice.increment).toFixed(2) : total.toFixed(2)} €
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const OverCartModal = () => {
  const { cartProducts } = useContext(CartContext)
  const booking = useContext(BookingContext)

  let total = 0;
  cartProducts.forEach((product) => {
    total = total + (product.price * product.quantity)
  })

  return (
    <ModalCart cartItems={cartProducts} total={total} booking={booking}/>
  )
}

export default OverCartModal;
