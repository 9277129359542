const stores = [
  {
    small: "Palma",
    direction: "Arabay Flagship Store Palma: Sindicat, 5 (esquina Plaça Major)",
    phone: "+34 871 037 996",
    showOnInfo: true,
  },
  {
    small: "Madrid",
    direction: "Arabay Flagship Store Madrid: Marqués Viudo de Pontejos, 1 (Sol)",
    phone: "+34 913 776 668",
    showOnInfo: true,
  },
  {
    small: "Eivissa",
    direction: "Ibiza Coffee Point: c/Castella, 30. Eivissa",
    phone: "+34 681 308 036",
    showOnInfo: true,
  },
  {
    small: "Mercat de l’Olivar",
    direction: "Olivar Coffee Point: Mercat de l’Olivar (Palma)",
    phone: "+34 871 037 996",
    showOnInfo: false,
  },
  {
    small: "Ciutadella",
    direction: "Menorca Coffee Point: c/Sabaters, 49 (Ciutadella de Menorca)",
    phone: "+34 682 781 544",
    showOnInfo: true,
  },
  {
    small: "Vistalegre",
    direction: "Arabay Coffee Point: Finca can Bieló (Lloret de Vistalegre, Mallorca)",
    phone: "+34 971 524 055",
    showOnInfo: true,
  },
]

export default stores;