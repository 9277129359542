import React from 'react';
import MyMap from "./MyMap";
import { Col, Row } from "reactstrap";
import stores from "./stores";

const Place = () => {
  return (
    <Row className="align-items-center">
      <Col xs={12} md={6} style={{ height: "500px" }} className="order-1 order-md-0">
        <Row className="h-100">
          <MyMap/>
        </Row>
      </Col>
      <Col xs={12} md={6} className="order-0 order-md-1">
        <Row className="h-100 center bg-secondary-90">
          <div className="text-center p-5">
            <h2 className="title-md mb-3">DIRECCIÓN</h2>
            {stores.map(({ direction }, index) => <p key={index} className="common-text-sm">{direction}</p>)}
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default Place;
