import React, { useContext } from "react";
import { Container, Row } from "reactstrap";
import { NavigationContext } from "../../wrappers/NavigationWrapper";
import { NavLink } from "react-router-dom";
import Hamburger from 'hamburger-react'
import logo from "./../../../media/logos/logo.png"
import { Route } from "react-router-dom";
// import links from "./links";
import cart from "./../../../media/icons/cartShopping.svg"
import { CartContext } from "../../wrappers/CartWrapper";
import { ModalContext } from "../../wrappers/ModalWrapper";
import ModalCart from "../modal/ModalCart";

const Navbar = () => {
  const { isOpenMenu, toggleMenu } = useContext(NavigationContext)

  // const pathsWithLogo = links.filter(({ logo }) => logo).map(({ path }) => path)
  return (
    <Container fluid style={styles.container} className={isOpenMenu ? "position-fixed" : "position-absolute"}>
      <Row className="justify-content-between align-items-center title-md">
        <div className="p-3">
          <Route path={'/'}>
            <NavLink to={'/'} style={{textDecoration: "none"}}><img height={39} src={logo} alt=""/></NavLink>
            
          </Route>
        </div>
        <div className="center">
          <CartControl/>
          <div className="pointer p-3" onClick={toggleMenu}>
            <Hamburger toggled={isOpenMenu}/>
          </div>
        </div>
      </Row>
    </Container>
  );
}

const CartControl = () => {
  const { numberOfCardProducts } = useContext(CartContext)
  const { changeModalContent } = useContext(ModalContext)
  return (
    <div style={{ cursor: "pointer" }} className="d-flex"
         onClick={() => changeModalContent(<ModalCart/>)}>
      <img width="30px" height="30px" src={cart} alt=""/>
      <div style={{ fontSize: "15px", marginLeft: "7px", width: "22px", fontWeight: "500" }}
           className="d-flex text-dark position-absolute justify-content-center">
        {numberOfCardProducts > 0 && numberOfCardProducts}
      </div>
    </div>
  );
};

const styles = {
  container: {
    zIndex: 1100,
    top: 0
  }
}

export default Navbar;