import React from "react";
import { Col, Container, Row } from "reactstrap";
import links from "./links"
import { NavLink } from "react-router-dom";
import europa from "./../../../media/icons/european/europa.png"
import europa1 from "./../../../media/icons/european/europaFooter.png"
import europa2 from "./../../../media/icons/european/goib.png"

const Footer = () => {
  return (
    <Container fluid className="bg-secondary-90">
      <Row className="p-2 p-lg-4 justify-content-between g-3">
        <Col xs={12} md={"auto"} className="px-0 py-2">
          {links.filter(({ footer }) => footer).map(({ path, title }, index) => {
            return (
              <div key={index} className="text-center text-md-left">
                <NavLink to={path}>
                  {title}
                </NavLink>
              </div>
            )
          })}
        </Col>
        <Col xs={12} md={"auto"}>
          <Row className="justify-content-center justify-content-md-end g-3 h-100 align-items-center">
            <Col xs="auto" className="my-2">
              <img height={50} src={europa2} alt=""/>
            </Col>
            <Col xs="auto" className="my-2">
              <img height={50} src={europa} alt=""/>
            </Col>
            <Col xs="auto" className="my-2">
              <img height={50} src={europa1} alt=""/>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;