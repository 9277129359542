import React from "react"
import instagram from "./../../../media/icons/socialMedia/instagram-brands.svg"
import facebook from "./../../../media/icons/socialMedia/facebook-brands.svg"
import linkedin from "./../../../media/icons/socialMedia/linkedin-brands.svg"
import twitter from "./../../../media/icons/socialMedia/twitter-brands.svg"


const SocialMedia = ({position}) => {
  return (
    <div style={{...styles.container, ...position}}>
      <a href="https://www.instagram.com/arabaycoffee" style={styles.link}>
        <img height="25px" src={instagram} alt=""/>
      </a>
      <a href="https://www.facebook.com/arabaycoffee" style={styles.link}>
        <img height="24px" src={facebook} alt=""/>
      </a>
      <a href="https://www.linkedin.com/company/arabaycoffee/mycompany/" style={styles.link}>
        <img height="24px" src={linkedin} alt=""/>
      </a>
      <a href="https://www.twitter.com/arabaycoffee" style={styles.link}>
        <img height="24px" src={twitter} alt=""/>
      </a>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    padding: '8px 15px 8px 6px',
    backgroundColor: '#161718'
  },
  link: {
    padding: 7
  }
}

export default SocialMedia;
