import React, { useContext } from 'react';
import posed from 'react-pose';
import { withRouter } from "react-router";
import Remove from "../../product/shared/Remove";
import Add from "../../product/shared/Add";
import { CartContext } from "../../../wrappers/CartWrapper";

const Options = posed.div({
  closed: {
    height: 0,
    delay: 200,
    transition: { duration: 200 }
  },
  open: {
    height: 'auto',
    delayChildren: 200,
    transition: { duration: 200 }
  }
});

const Btoptions = posed.div({
  open: { y: "-50%", opacity: 1, transition: { duration: 200 } },
  closed: { y: 0, opacity: 0, transition: { duration: 200 } }
});

const ProductCart = ({ product, isOpen, open }) => {

  const {
    addCardProduct,
    subtractCartProduct,
  } = useContext(CartContext)

  const realPriceProduct = product.price;

  const add = () => {
    addCardProduct(product)
  }

  const subtract = () => {
    subtractCartProduct(product.item_code)
  }


  return (
    <React.Fragment>
      <div style={{ cursor: "pointer" }} onClick={open}
           className="row justify-content-between align-items-end py-2 bg-secondary-20">
        <div className="col">
          {product.item_name}
        </div>
        <div style={{ fontSize: "20px", opacity: (isOpen ? 0 : 1) }} className="col-auto">
          {(realPriceProduct * product.quantity).toFixed(2)} €
        </div>
      </div>
      <Options className="row overflow-hidden justify-content-end bg-secondary-10" pose={isOpen ? "open" : "closed"}>
        <Btoptions style={{ zIndex: 20 }} className="position-absolute d-flex px-2">
          <Remove size={product.quantity < 1 ? "medium" : "small"} onClick={subtract} drop={product.quantity < 1}/>
          <div className="mx-1"/>
          <Add size="medium" num={product.quantity} onClick={add}/>
        </Btoptions>
        <div className="col-12 mt-4 mb-2">
          <div>
            {product.item_property && product.item_property}
          </div>
          <div style={{ fontSize: "20px", opacity: (isOpen ? 1 : 0) }} className="d-flex justify-content-end">
            {(realPriceProduct * product.quantity).toFixed(2)} €
          </div>
        </div>
      </Options>
    </React.Fragment>
  );
};

export default withRouter(ProductCart);
