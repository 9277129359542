import React, { PureComponent } from 'react';
import { Button } from "reactstrap";
/*
unused
const MoveDiv = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    boxShadow: '0px 0px 0px rgba(0,0,0,0)'
  },
  hover: {
    scale: 1.1,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
  },
  press: {
    scale: 1.05,
    boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
  }
});*/

class Finish extends PureComponent {

  render() {

    return (
      <div className="container-fluid my-5">
        <div style={{ fontSize: "20px" }} className="row justify-content-center text-center mb-3">
          FINALIZAR PEDIDO
        </div>
        <div className="row justify-content-center">
          <Button size="lg" color="secondary" onClick={this.props.finish} style={{ maxWidth: "300px" }}>
            REALIZAR PEDIDO
          </Button>
        </div>
      </div>
    );
  }
}

export default Finish;
