import React from "react";
import classNames from "classnames"
import { Row } from "reactstrap";
import DivImg from "./DivImg";

const Banner = ({ children, title, img, style = {} }) => {

  const realStyle = {
    minHeight: 200,
    ...style
  }

  return (
    <DivImg className="container-fluid bg-secondary" img={img} style={realStyle}>
      <Row className={classNames("center h-100", { "title-xl font-weight-bold pt-5": title })}>
        {title || children}
      </Row>
    </DivImg>
  );
}

export default Banner;