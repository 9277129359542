import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import Banner from "../shared/decoration/Banner";
import { StockContext } from "../wrappers/StockWrapper";
import Loading from "../shared/loading/Loading";
import DivImg from "../shared/decoration/DivImg";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import productsCategories from "./shop/productsCategories";

const ShopSections = () => {
  const { products } = useContext(StockContext)

  if (!products) return <Loading/>

  return (
    <Container fluid>
      <Row>
        <Banner title="TIENDA ONLINE" style={{ height: 200 }}/>
      </Row>
      <Row className="p-2 p-md-5">
        <Container>
          <Row>
            {productsCategories.map(({ path, ...category }, index) => {
              return (
                <Col key={index} xs={12} lg={index <= 1 ? 6 : 4} className="py-3">
                  <NavLink to={path}>
                    <Box {...category}/>
                  </NavLink>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

const Box = ({ title, image, onClick }) => {
  return (
    <div className="position-relative h-100 pointer" onClick={onClick}>
      <motion.div initial={{ opacity: 0.7 }} whileHover={{ opacity: 1 }}>
        <DivImg img={image} className="w-100 center title-lg font-weight-bold" style={styles.category}/>
      </motion.div>
      <div className="position-absolute title-lg font-weight-bold"
           style={{ top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)" }}>
        {title}
      </div>
    </div>
  )
}

const styles =
  {
    category: {
      height: 300
    }
  }

export default ShopSections;