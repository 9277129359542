import React from 'react';

const EmptyCart = () => {
  return (
    <div className="container-fluid h-100 text-dark">
      <div className="row h-100 justify-content-center align-items-center">
        <div style={{fontSize: "20px"}} className="text-center px-3 px-md-5">
          Parece que tu carrito está vacío. Tranquilo, tenemos muchos productos para calmar tus ansias de café.
        </div>
      </div>
    </div>
  );
};

export default EmptyCart;
