import React from 'react';
import { NavLink } from "react-router-dom";

const CookiesModal = ({ accept }) => {
  return (
    <div className="bg-white p-5"
         style={{ minWidth: "300px", width: "90vw", maxWidth: "600px", minHeight: "200px" }}>
      <div className="row justify-content-center text-center text-dark no-gutters">
        <p><b>TU PRIVACIDAD ES IMPORTANTE PARA NOSOTROS</b></p>
        <p className="mb-2">
          Esta web utiliza cookies. Si sigues navegando consientes el uso de esta tecnología en nuestra web.
          Para más información visita nuestros apartados
        </p>
        <a href="https://stripe.com/cookies-policy/legal" className="w-100">
          Cookies Stripe
        </a>
        <NavLink to="/cookies" className="text-center w-100">
          Cookies
        </NavLink>
        <button className="btn btn-primary mt-3" onClick={accept}>
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default CookiesModal;
