import React from "react";

const Loading = () => {
  return (
    <div className="vh-100 w-100 center title-md">
      Cargando...
    </div>
  );
}

export default Loading;