import React from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";

const MyInput = ({title, name, type, notEmpty, onChange, value}) => {
  return (
    <Row>
      <FormGroup className="w-100">
        <Label for={name}>{title}</Label>
        <Input bsSize="lg" required={notEmpty} type={type} name={name} id={name}
               placeholder="" onChange={onChange}
               value={value}/>
      </FormGroup>
    </Row>
  )
}

export default MyInput;