import React, { createContext, useState } from "react";

export const BookingContext = createContext();

const BookingWrapper = ({ children }) => {
  const [state, setState] = useState({
    items: [],
    is_pickup: 0,
    pickup_location: "",
    is_same_for_shipping: 1,
    billing_address: "",
    shipping_address: "",
    shipping_rule: ""
  })

  const changeBooking = (key, value) => {
    setState((actual) => ({ ...actual, [key]: value }))
  };

  return (
    <BookingContext.Provider value={{ booking: state, changeBooking, change: changeBooking }}>
      {children}
    </BookingContext.Provider>
  );
}

export default BookingWrapper;