import React from "react";
import classNames from "classnames"

const DivImg = ({ img, style = {}, className, ...props }) => {
  const theStyle = {
    ...(img ? {
      backgroundImage: `url(${img})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    } : {}),
    ...style
  }

  return (
    <div className={classNames(className)} style={theStyle} {...props}>

    </div>
  )
}

export default DivImg;
