import React, { createContext, useState } from "react";

export const CartContext = createContext();

const CartWrapper = ({ children }) => {
  const [cartProducts, setCartProducts] = useState([])
  const [numberOfCardProducts, setNumberOfCardProducts] = useState(0)

  const addCardProduct = (product) => {
    const { foundProduct, index } = findCardProduct(product.item_code);
    const copyCart = cartProducts;
    if (foundProduct) {
      copyCart[index].quantity = (copyCart[index].quantity + 1)
    } else {
      copyCart.push({ ...product, quantity: 1 })
    }
    setNumberOfCardProducts((actualNumber) => actualNumber + 1)
    setCartProducts(() => copyCart)
  }

  const removeCartProduct = (item_code) => {
    const { foundProduct, index } = findCardProduct(item_code);
    const copyCart = cartProducts;
    if (foundProduct) {
      copyCart.splice(index, 1);
    }
    setNumberOfCardProducts((actualNumber) => actualNumber - foundProduct.quantity)
    setCartProducts(() => copyCart)
  }

  const subtractCartProduct = (item_code) => {
    const { foundProduct, index } = findCardProduct(item_code);
    const copyCart = cartProducts;
    if (foundProduct) {
      if (foundProduct.quantity >= 2) copyCart[index].quantity = (copyCart[index].quantity - 1)
      else copyCart.splice(index, 1);
    }
    setNumberOfCardProducts((actualNumber) => actualNumber - 1)
    setCartProducts(() => copyCart)
  }

  const findCardProduct = (item_code) => {
    let productIndex;
    const product = cartProducts.find((product, index) => {
      productIndex = index
      return (product.item_code === item_code)
    })
    return { foundProduct: product, index: productIndex }
  }


  // console.log("cart: ", cartProducts)
  return (
    <CartContext.Provider
      value={{ cartProducts, addCardProduct, numberOfCardProducts, subtractCartProduct, removeCartProduct, findCardProduct }}>
      {children}
    </CartContext.Provider>
  );
}

export default CartWrapper;