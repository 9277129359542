import React, { createContext, useEffect, useState } from "react";
import { getProducts } from "../../api/apiRequest";
import { toast } from 'react-toastify';

export const StockContext = createContext();

const StockWrapper = ({ children }) => {
  const [products, setProducts] = useState()

  useEffect(() => {
    getProducts()
      .then(({ data }) => {
        const products = [];
        data.message.filter(item => item.price !== null).forEach(item => {
          const { newProductComplete, subProducts } = redistributionProductInSubProducts(item);
          products.push(newProductComplete);
          products.push(...subProducts);
        });
        if (products.length > 0) {
          setProducts(products);
        }
      })
      .catch(() => toast.error("Error al obtener los productos"))
  }, [])

  const findProduct = (item_code) => {
    if (products) return products.find((product) => (product.item_code === item_code))
    else return null;
  }

  return (
    <StockContext.Provider value={{ products, setProducts, findProduct }}>
      {children}
    </StockContext.Provider>
  );
}


const redistributionProductInSubProducts = (product) => {
  const { properties, rel_items, ...others } = product;
  const subProducts = [];

  if (rel_items && rel_items.length > 0) {
    rel_items.forEach(subItem => {
      subProducts.push(...getItemsByProps(subItem, product.properties));
    })
  }
  subProducts.push(...getItemsByProps(product));
  return { newProductComplete: { ...others, properties, rel_items: subProducts }, subProducts }
};

const getItemsByProps = (product, propertiesDefault = []) => {
  const { properties } = product;
  const subProducts = [];

  const props = [];

  if (properties && properties.length > 0) {
    const grindingProp = getGrindingProperty(properties)
    props.push(...(type[grindingProp] || []))
  } else {
    if (propertiesDefault && propertiesDefault.length > 0) {
      const grindingProp = getGrindingProperty(propertiesDefault)
      props.push(...(type[grindingProp] || []))
    }
  }

  props.forEach(prop => {
    const newProduct = { ...product };
    const preProp = (newProduct.item_property ? (newProduct.item_property + ": ") : "");
    newProduct.item_property = (preProp + prop);
    newProduct.item_description = prop;
    newProduct.ref_code = newProduct.item_code;
    newProduct.item_code = (newProduct.item_code + ": " + prop);
    newProduct.ocult = true;
    subProducts.push(newProduct);
  });
  return subProducts;
};

const type = {
  "Disponible en Grano y Molido": [
    "En Grano",
    "Molido para Espresso",
    "Molido para Moka (italiana)",
    "Molido para filtro (chemex o similar)"
  ],
  "Solo Molido": [
    "Molido para Espresso",
    "Molido para Moka (italiana)",
    "Molido para filtro (chemex o similar)"],
  "Solo Grano": [
    "En Grano"
  ]
};

const grindingProperties = ["Disponible en Grano y Molido", "Solo Molido", "Solo Grano"]

const getGrindingProperty = (properties) => {
  for (let prop of properties) {
    if (grindingProperties.includes(prop)) {
      return prop
    }
  }
  return null
}

export default StockWrapper;