import React, { createContext, useState } from "react";
import Modal from "../shared/modal/Modal";

export const ModalContext = createContext();

const ModalWrapper = ({ children }) => {
  const [{ modalContent, to }, setState] = useState({
    modalContent: null,
    to: null
  })

  const closeModal = () => {
    changeModalContent(null)
  };

  const changeModalContent = (newContent, to = null) => {
    setState({ modalContent: newContent, to: to })
  };

  return (
    <ModalContext.Provider value={{ changeModalContent }}>
      <Modal children={modalContent} closeModal={closeModal} to={to}/>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalWrapper;