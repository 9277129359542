import React from 'react';
import Fab from '@material-ui/core/Fab';
import RemoveIcon from '@material-ui/icons/Remove';
import Delete from '@material-ui/icons/Delete';

const Remove = ({onClick, size = "large", drop = false}) => {
  return (
    <Fab size={size} onClick={onClick}>
      {drop ? <Delete/> : <RemoveIcon/>}
    </Fab>
  );
};

export default Remove;
