const links = [
  {
    title: "HOME",
    path: "/",
    footer: true,
    logo: false
  },
  {
    title: "ABOUT US",
    path: "/about-us",
    footer: true,
    logo: true
  },
  {
    title: "STORES",
    path: "/stores",
    footer: true,
    logo: true
  },
  {
    title: "ONLINE SHOP",
    path: "/shop",
    footer: true,
    logo: true
  },
  {
    title: "CONTACT",
    path: "/contact",
    footer: true,
    logo: true
  },
  {
    title: "INTRANET",
    path: "*",
    footer: false,
    logo: false
  }
]

export default links;