import React, { useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";


const REACT_APP_W3FORMS_KEY = process.env.REACT_APP_W3FORMS_KEY;

const ContactForm = ({ section }) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    place: 'Mallorca'
  })

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const validateForm = () => {
    const info = state
    for(var input in info) {
      if(!info[input]) return false
    }
    return true
  }

  // const getHtmlForEmail = () => {
  //   const info = state;
  //   return `<div><p><b>Nombre:</b> ${info.name}</p><p><b>Email:</b> ${info.email}</p><p><b>Teléfono:</b> ${info.phone}</p><p><b>Mensaje:</b></p><p style="border: 1px solid darkgray; min-height: 50px; padding: 7px 7px 7px 7px">${info.message}</p><h6>Esta solicitud se ha enviado desde la sección de ${section ? section : "CONTACTO"} de arabay</h6></div>`
  // };

  const onSubmit = (event) => {
    event.preventDefault();
    if(validateForm()){



      const info = state;

      const infoToSend = {...info, subject: "ARABAY - Solicitud de información", access_key: REACT_APP_W3FORMS_KEY, replyto: info.email, from_name: "ARABAY"}


      fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(infoToSend),
      })
        .then(async (response) => {
          let json = await response.json();
          if (json.success) {
            toast("Su duda ha sido enviada, en breve recibirá una respuesta.");
          } else {
            toast("La solicitud no se ha podido enviar correctamete, intentelo en unos instantes");
          }
        })
        .catch(() => {
          toast("La solicitud no se ha podido enviar correctamete, intentelo en unos instantes");
        });




      // const email = {
      //   Mallorca: "palma@arabaycoffee.com",
      //   Menorca: "info@arabaycoffee.com",
      //   Madrid: "madrid@arabaycoffee.com",
      //   Ibiza: "ibiza@arabaycoffee.com"
      // }
      // sendEmail({
      //   title: "ARABAY - Solicitud de información",
      //   html: getHtmlForEmail(),
      //   to: email[state.place]
      // }).then(() => {
      //   toast("Su duda ha sido enviada, en breve recibirá una respuesta.");
      // }).catch(() => {
      //   toast("La solicitud no se ha podido enviar correctamete, intentelo en unos instantes");
      // })


    } else toast("Por favor, rellene todos los campos");
  }

  return (
    <Container fluid className="bg-primary p-5">
      <Row form>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="name">Nombre</Label>
            <Input bsSize="lg" required type="text" name="name" id="name" placeholder="Nombre" onChange={onChange}
                   value={state.name}/>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input bsSize="lg" required type="email" name="email" id="email" placeholder="Email" onChange={onChange}
                   value={state.email}/>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="phone">Teléfono</Label>
            <Input bsSize="lg" required type="number" name="phone" id="phone" placeholder="Teléfono" onChange={onChange}
                   value={state.phone}/>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="place">Lugar</Label>
            <Input bsSize="lg" type="select" name="place" id="place" onChange={onChange} value={state.place}>
              <option>Mallorca</option>
              <option>Menorca</option>
              <option>Madrid</option>
              <option>Ibiza</option>
            </Input>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label for="message">Mensaje</Label>
            <Input style={{ height: "100px" }} bsSize="lg" required type="textarea" name="message" id="message"
                   placeholder="" onChange={onChange}
                   value={state.message}/>
          </FormGroup>
        </Col>
        <Col>
          <Row className="center mt-4">
            <Button size="lg" color="secondary" onClick={onSubmit}>
              SOLICITAR INFORMACIÓN
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactForm;