import React, { PureComponent } from 'react';
import posed from 'react-pose';

const MoveDiv = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    boxShadow: '0px 0px 0px rgba(0,0,0,0)'
  },
  hover: {
    scale: 1.1,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
  },
  press: {
    scale: 1.05,
    boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
  }
});

class SelectedMethod extends PureComponent {

  componentDidMount() {
    const { getMethods } = this.props;
    getMethods();
  }

  selectMethod = (method) => {
    this.props.selectMethod(method)
  };


  render() {
    const { methods, errorMethods } = this.props;

    if (errorMethods) {
      return <div className="container-fluid">
        No se han encontrado métodos de envío ni tiendas cercanas
      </div>
    }

    if (methods === null) {
      return <div className="container-fluid">
        Cargando métodos de envío...
      </div>
    }

    return (
      <div className="container-fluid my-5">
        <div style={{ fontSize: "20px" }} className="row justify-content-center text-center mb-3">
          ENVÍO O RECOGIDA
        </div>
        <div className="row justify-content-center my-4">
          {methods.methods.map((method, key) =>
            <MoveDiv onClick={() => this.selectMethod(method)} key={key} style={{ width: "260px", cursor: "pointer" }}
                     className="col-auto m-2 bg-white text-dark py-4 px-5 border border-primary">
              <div className="row justify-content-center mb-3 border-bottom border-primary">
                <strong>{method.name}</strong>
              </div>
              {method.price > 0 ?
              <div className="row">
                Precio: {method.price.toFixed(2)} €
              </div> :
                <div className="row">
                 Sin coste
                </div>
              }
            </MoveDiv>
          )}
          {methods.methods.length <= 0 && "No disponemos de envíos a esa dirección"}
        </div>
      </div>
    );
  }
}

export default SelectedMethod;
