import React, { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Add from "./shared/Add";
import Remove from "./shared/Remove";
import grayWallpaper from "../../../media/walls/grayWallpaper.jpg"
import coffeeIcon from "./../../../media/icons/coffeeBack.svg"
import "./product.css"
import Select from 'react-select'
import DivImg from "../decoration/DivImg";
import { useHistory } from "react-router";
import { CartContext } from "../../wrappers/CartWrapper";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;


const Product = ({ product, subtract, add, remove }) => {
  const { findCardProduct, addCardProduct, subtractCartProduct, removeCartProduct, numberOfCardProducts } = useContext(CartContext)
  const history = useHistory();

  const [hoverButton, setHoverButton] = useState(false);
  const newProduct = (product.rel_items && product.rel_items.length > 0) ? { ...product.rel_items[0] } : { ...product };
  const property = newProduct.item_property || "";
  const [selectedProduct, setSelectedProduct] = useState({ value: newProduct, label: property });


  const { foundProduct } = useMemo(() => {
    return findCardProduct(selectedProduct.value.item_code)
    // eslint-disable-next-line
  }, [selectedProduct.value.item_code, numberOfCardProducts]);

  const quantity = (foundProduct || {}).quantity


  const notification = (text, opt) => {
    toast(text, { ...opt, containerId: "store" });
  };

  const addProduct = () => {
    !selectedProduct.value.quantity && notification("Se ha añadido " + selectedProduct.value.item_name + ": " + selectedProduct.label + " al carrito de compra", { className: "bg-success text-white" });
    addCardProduct(selectedProduct.value);
  };

  const subProduct = () => {
    notification("Se ha eliminado un producto de su carrito de compra: " + selectedProduct.value.item_name + ": " + selectedProduct.label, { className: "bg-danger text-white" });
    subtractCartProduct(selectedProduct.value.item_code)
  };

  const remProduct = () => {
    notification("Se ha eliminado un producto de su carrito de compra: " + selectedProduct.value.item_name + ": " + selectedProduct.label, { className: "bg-danger text-white" });
    removeCartProduct(selectedProduct.value.item_code);
  };

  let img = coffeeIcon;
  if (selectedProduct.value.thumbnail) {
    img = REACT_APP_API_URL + "image" + selectedProduct.value.thumbnail
  } else if (selectedProduct.value.website_image) {
    img = REACT_APP_API_URL + "image" + selectedProduct.value.website_image
  }

  const goToProductPage = () => {
    if (!hoverButton) {
      history.push("/shop/product/" + product.item_code)
    }
  };


  const options = [];

  if (product.rel_items) {
    product.rel_items.forEach((item) => {
      if (item.item_property) {
        options.push({ value: item, label: item.item_property })
      }
    })
  }

  return (
    <div style={{ width: "300px", cursor: "pointer" }} onClick={goToProductPage}
         className="bg-light m-1">
      <DivImg className="w-100 h-100 d-flex flex-column" img={grayWallpaper}
              style={{ alignContent: "stretch" }}>
        <DivImg className="container-fluid bg-light" style={{ height: 300 }} img={img}>
          <div className="row h-100 align-items-end justify-content-end">
            <div className="d-flex justify-content-end px-2 productTools" onMouseEnter={() => setHoverButton(true)}
                 onMouseLeave={() => setHoverButton(false)}>
              <div className="d-flex align-items-center">
                {subtract &&
                <Remove size={add && "medium"} onClick={subProduct} drop={quantity < 1}/>}
                <div className="mx-1">
                </div>
                {add && <Add onClick={addProduct} num={quantity}/>}
              </div>
            </div>
          </div>
        </DivImg>
        <div className="container-fluid pt-3 pb-2 d-flex flex-column"
             style={{ flexGrow: 1, justifyContent: "space-between" }}>
          <div style={{ fontSize: "18px" }} className="row px-3">
            {selectedProduct.value.price} €
          </div>
          <div style={{ fontSize: "20px", letterSpacing: "1px" }} className="row px-3">
            {selectedProduct.value.item_name}
          </div>
          <div style={{ minHeight: "100px", fontSize: "14px" }} className="row px-3 mt-4">
            {selectedProduct.value.item_property}
          </div>
          {options.length > 1 && <div onMouseEnter={() => setHoverButton(true)}
                                      onMouseLeave={() => setHoverButton(false)}>
            <Select classNamePrefix={"select"} options={options} value={selectedProduct} onChange={(newValue) => {
              setSelectedProduct(newValue)
            }}/>
          </div>}

          {remove &&
          <div style={{ transform: "translateY(-100%)" }} className="row justify-content-end">
            <div style={{ transform: "translateY(-100%)" }} className="px-2 position-absolute">
              {(selectedProduct.value.quantity > 0) && <Remove size={"medium"} onClick={remProduct} drop/>}
            </div>
          </div>
          }
        </div>
      </DivImg>
    </div>
  );
};

export default Product;
