import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Switch, Route, Redirect, BrowserRouter, useLocation } from "react-router-dom";
import NavigationWrapper, { NavigationContext } from "./components/wrappers/NavigationWrapper";
import Home from "./components/pages/Home";
import { useContext, useEffect } from "react";
import AboutUs from "./components/pages/AboutUs";
import Contact from "./components/pages/Contact";
import ShopSections from "./components/pages/ShopSections";
import StockWrapper from "./components/wrappers/StockWrapper";
import Shop from "./components/pages/Shop";
import CartWrapper from "./components/wrappers/CartWrapper";
import ProductPage from "./components/pages/ProductPage";
import { Slide, toast, ToastContainer } from "react-toastify";
import Cookies from "./components/shared/legal/Cookies";
import ModalWrapper from "./components/wrappers/ModalWrapper";
import PaymentSuccess from "./components/pages/infoPayment/PaymentSuccess";
import PaymentDenied from "./components/pages/infoPayment/PaymentDenied";
import BookingWrapper from "./components/wrappers/BookingWrapper";

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        pauseOnFocusLoss={false}
        closeButton={false}
        hideProgressBar
        position={toast.POSITION.TOP_RIGHT}
        transition={Slide}
      />
      <StockWrapper>
        <BookingWrapper>
          <CartWrapper>
            <ModalWrapper>
              <NavigationWrapper>
                <ScrollToTop/>
                <Cookies/>
                <Switch>
                  <Route exact path="/" component={Home}/>
                  <Route exact path="/about-us" component={AboutUs}/>
                  <Route exact path={["/contact", "/stores"]} component={Contact}/>
                  <Route exact path="/shop" component={ShopSections}/>
                  <Route exact path="/shop/product/:id" component={ProductPage}/>
                  <Route exact path="/shop/:category" component={Shop}/>
                  <Route exact path="/paymentSuccess" component={PaymentSuccess}/>
                  <Route exact path="/paymentDenied" component={PaymentDenied}/>
                  <Redirect to={"/"}/>
                </Switch>
              </NavigationWrapper>
            </ModalWrapper>
          </CartWrapper>
        </BookingWrapper>
      </StockWrapper>
    </BrowserRouter>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const { isOpenMenu, setOpenMenu } = useContext(NavigationContext)
  useEffect(() => {
    if (isOpenMenu) setOpenMenu(false);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);
  return null;
};

export default App;
