import React, { useContext } from "react";
import { motion } from "framer-motion";
import { NavigationContext } from "../../wrappers/NavigationWrapper";
import { Col, Row } from "reactstrap";
import BackgroundVideo from "../video/BackgroundVideo";
import video from "./../../../media/video/coffeeOriginal.mp4"
import { NavLink } from "react-router-dom";
import links from "./links"

const variantsContainer = {
  closed: {
    height: 0,
    transition: { ease: "easeInOut", duration: 0.3, delay: 0.3 }
  },
  opened: {
    height: '100vh',
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
      ease: "easeInOut",
      duration: 0.3
    }
  }
}

const variantsVideo = {
  closed: {
    opacity: 0
  },
  opened: {
    opacity: 1
  }
}

const variantsLinks = {
  closed: {
    opacity: 0
  },
  opened: {
    opacity: 1
  }
}

const Menu = () => {
  const { isOpenMenu } = useContext(NavigationContext)
  return (
    <motion.div style={styles.container} transition={{ ease: "easeInOut", duration: 0.5 }} initial="closed"
                animate={isOpenMenu ? "opened" : "closed"}
                variants={variantsContainer} className="container-fluid bg-primary position-fixed overflow-hidden">
      <Row className="h-100">
        <Col xs={12} lg={6} className="d-none d-lg-block">
          <motion.div className="row h-100" variants={variantsVideo}>
            <BackgroundVideo url={video}/>
          </motion.div>
        </Col>
        <Col xs={12} lg={6}>
          <Row className="flex-column h-100 justify-content-center title-sm">
            {links.map(({ title, path }, index) => {
              return (
                <motion.div key={index} variants={variantsLinks} className="center w-100 my-1 no-gutters">
                  <NavLink to={path} style={{ textDecoration: "none", fontSize: "20px" }}>{title}</NavLink>
                </motion.div>
              )
            })}
          </Row>
        </Col>
      </Row>
    </motion.div>
  );
}

const styles = {
  container: {
    zIndex: 1000,
    top: 0,
  }
}

export default Menu;