import request from "./request";

const GENERAL_CLIENT_USR = process.env.REACT_APP_API_GENERAL_CLIENT_USR;
const GENERAL_CLIENT_PWD = process.env.REACT_APP_API_GENERAL_CLIENT_PWD;
const REACT_APP_HASH_STORE = process.env.REACT_APP_HASH_STORE;

// AUTH

export const login = (usr = GENERAL_CLIENT_USR, pwd = GENERAL_CLIENT_PWD) => {
  const data = new FormData();
  data.append("usr", (REACT_APP_HASH_STORE + "__" + usr));
  data.append("pwd", pwd);

  return request().post("api/method/login", data)
};

export const logout = () => {
  return request().post("api/method/logout")
};

export const signUp = ({ email, first_name, last_name, password, phone }) => {
  return request().post("api/method/online_store.user.sign_up", {
    email,
    first_name,
    last_name,
    password,
    phone
  })
};


// PRODUCTS

export const getProducts = () => request().get("api/method/online_store.items.get");


// CART

export const selectedMethod = ({ address_name, price }) => {
  return request().get("api/method/online_store.user.get_shipping_methods?address=" + encodeURIComponent(address_name) + "&price=" + price)
};

export const addAddress = ({ address_title, address_line1, address_line2, city, state, pincode, country, email_id, phone, is_primary_address = 1, is_shipping_address = 1 }) => {
  const objectAdress = {
    "address_title": address_title,
    "address_type": "Billing",
    "address_line1": address_line1,
    "address_line2": address_line2,
    "city": city,
    "state": state,
    "pincode": pincode,
    "country": country,
    "email_id": email_id,
    "phone": phone,
    "is_primary_address": is_primary_address,
    "is_shipping_address": is_shipping_address,
    "doctype": "Address",
    "name": ""
  };
  const str = JSON.stringify(objectAdress);
  const resultado = encodeURIComponent(str);

  return request().post("api/method/frappe.website.doctype.web_form.web_form.accept?data=" + resultado + "&web_form=addresses")
};

export const makePerchuse = (perchuse) => {
  return request().post("api/method/online_store.sales.new_sale", perchuse)
};

export const getAdresses = () => {
  return request().get("api/method/online_store.user.get_addresses")
};
