import React, { PureComponent } from 'react';
import posed from 'react-pose';
import { toast } from "react-toastify";
import Select from "react-select";
import { addAddress, getAdresses } from "../../../../api/apiRequest";
import { Button } from "reactstrap";
import MyInput from "../../../old/MyInput";

const MoveDiv = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    boxShadow: '0px 0px 0px rgba(0,0,0,0)'
  },
  hover: {
    scale: 1.1,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
  },
  press: {
    scale: 1.05,
    boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
  }
});

class SelectAddress extends PureComponent {

  state = {
    addresses: [],
    newAddress: false,
    address_title: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: { label: "Balears", value: "Balears" },
    pincode: "",
    country: { label: "España", value: "Spain" },
    email_id: "",
    phone: "",
  };
  errors = [];

  captureErrors = (where, isCorrect) => {
    const indice = this.errors.indexOf(where);
    const existsIndice = indice >= 0;
    isCorrect ? (existsIndice && this.errors.splice(indice, 1)) : (!existsIndice && this.errors.push(where));
  };

  changeInfo = (event) => this.setState({
    [event.target.name]: (event.target.value)
  });


  submit = (event) => {
    event.preventDefault();
    if (this.errors.length < 1) {
      this.createNewAddress();
    } else {
      toast(("Nos sería más fácil identificarte si rellenas todos los datos correctamente"), {
        className: "bg-danger text-white",
        containerId: "store"
      });
    }
  };

  createNewAddress = () => {
    const { address_line1, address_line2, address_title, city, country, email_id, phone, pincode, state } = this.state;
    addAddress({
      address_title,
      phone,
      state: state.value,
      city,
      address_line1,
      address_line2,
      country: country.value,
      pincode,
      email_id
    }).then(() => {
      toast(("Se ha guardado una nueva dirección, en breves momentos podrás verla"), {
        className: "bg-success text-white",
        containerId: "store"
      });
      this.obtainAdresses();
    }).catch(() => {
      toast(("¡Ups! Parece que no se ha podido guardar la nueva dirección, vuelva a intentarlo más tarde"), {
        className: "bg-danger text-white",
        containerId: "store"
      });
    })
  };

  componentDidMount() {
    this.obtainAdresses();
  }

  obtainAdresses = () => {
    getAdresses()
    .then(some => {
      // console.log(some.data.message)
      this.setState({addresses: some.data.message ? some.data.message : []})
    })
    .catch(() => {
      toast(("¡Ups! Hemos encontrado un error al recuperar tus direcciones, vuelva a intentarlo más tarde"), {
        className: "bg-danger text-white",
        containerId: "store"
      });
    })
  };

  getFormNewAddress = () => {
    this.setState({ newAddress: true })
  };

  changeCountry = (value) => {
    this.setState({ country: value })
  };
  changeState = (value) => {
    this.setState({ state: value })
  };

  selectAddress = (address) => {
    this.props.selectAddress(address)
  };

  render() {
    const { addresses, newAddress, address_line1, address_line2, address_title, city, country, email_id, phone, pincode, state } = this.state;
    if (addresses === null) {
      return <div className="container-fluid">
        Cargando direcciones...
      </div>
    }


    const countries = [
      { label: "España", value: "Spain" },
      { label: "Alemania", value: "Germany" },
      { label: "Austria", value: "Austria" },
      { label: "Bélgica", value: "Belgium" },
      { label: "Bosnia y Herzegovina", value: "Bosnia and Herzegovina" },
      { label: "Bulgaria", value: "Bulgaria" },
      { label: "Chipre", value: "Cyprus" },
      { label: "Dinamarca", value: "Denmark" },
      { label: "Eslovaquia", value: "Slovakia" },
      { label: "Eslovenia", value: "Slovenia" },
      { label: "Estonia", value: "Estonia" },
      { label: "Finlandia", value: "Finland" },
      { label: "Francia", value: "France" },
      { label: "Grecia", value: "Greece" },
      { label: "Hungría", value: "Hungary" },
      { label: "Irlanda", value: "Ireland" },
      { label: "Italia", value: "Italy" },
      { label: "Letonia", value: "Latvia" },
      { label: "Liechtenstein", value: "Liechtenstein" },
      { label: "Lituania", value: "Lituania" },
      { label: "Luxemburgo", value: "Luxemburgo" },
      { label: "Malta", value: "Malta" },
      { label: "Países Bajos", value: "Netherlands" },
      { label: "Polonia", value: "Poland" },
      { label: "Portugal", value: "Portugal" },
      { label: "Reino Unido", value: "United Kingdom" },
      { label: "República Checa", value: "Czech Republic" },
      { label: "Rumania", value: "Romania" },
      { label: "Suecia", value: "Sweden" },
      { label: "Suiza", value: "Switzerland" },
      { label: "Ucrania", value: "Ukraine" }
    ];

    const states = [
      { label: "A Coruña", value: "A Coruña" },
      { label: "Alacant", value: "Alacant" },
      { label: "Álava", value: "Álava" },
      { label: "Albacete", value: "Albacete" },
      { label: "Almería", value: "Almería" },
      { label: "Asturias", value: "Asturias" },
      { label: "Ávila", value: "Ávila" },
      { label: "Badajoz", value: "Badajoz" },
      { label: "Balears", value: "Balears" },
      { label: "Barcelona", value: "Barcelona" },
      { label: "Bizkaia", value: "Bizkaia" },
      { label: "Burgos", value: "Burgos" },
      { label: "Cáceres", value: "Cáceres" },
      { label: "Cádiz", value: "Cádiz" },
      { label: "Cantabria", value: "Cantabria" },
      { label: "Castelló", value: "Castelló" },
      { label: "Ciudad Real", value: "Ciudad Real" },
      { label: "Córdoba", value: "Córdoba" },
      { label: "Cuenca", value: "Cuenca" },
      { label: "Gipuzkoa", value: "Gipuzkoa" },
      { label: "Girona", value: "Girona" },
      { label: "Granada", value: "Granada" },
      { label: "Guadalajara", value: "Guadalajara" },
      { label: "Huelva", value: "Huelva" },
      { label: "Huesca", value: "Huesca" },
      { label: "Jaén", value: "Jaén" },
      { label: "La Rioja", value: "La Rioja" },
      { label: "Las Palmas", value: "Las Palmas" },
      { label: "León", value: "León" },
      { label: "Lleida", value: "Lleida" },
      { label: "Lugo", value: "Lugo" },
      { label: "Madrid", value: "Madrid" },
      { label: "Málaga", value: "Málaga" },
      { label: "Murcia", value: "Murcia" },
      { label: "Nafarroa", value: "Nafarroa" },
      { label: "Ourense", value: "Ourense" },
      { label: "Palencia", value: "Palencia" },
      { label: "Pontevedra", value: "Pontevedra" },
      { label: "Salamanca", value: "Salamanca" },
      { label: "Santa Cruz de Tenerife", value: "Santa Cruz de Tenerife" },
      { label: "Segovia", value: "Segovia" },
      { label: "Sevilla", value: "Sevilla" },
      { label: "Soria", value: "Soria" },
      { label: "Tarragona", value: "Tarragona" },
      { label: "Teruel", value: "Teruel" },
      { label: "Toledo", value: "Toledo" },
      { label: "València", value: "València" },
      { label: "Valladolid", value: "Valladolid" },
      { label: "Zamora", value: "Zamora" },
      { label: " Ceuta", value: " Ceuta" },
      { label: " Melilla", value: " Melilla" }
    ];


    return (
      <div className="container-fluid my-5">
        <div style={{ fontSize: "20px" }} className="row justify-content-center text-center mb-3">
          DIRECCIONES
        </div>
        <div className="row justify-content-center my-4">
          {addresses.map((address, key) =>
            <MoveDiv onClick={() => this.selectAddress(address)} key={key} style={{ width: "260px", cursor: "pointer" }}
                     className="col-auto m-2 bg-white text-dark py-4 px-5 border border-primary">
              <div className="row justify-content-center mb-3 border-bottom border-primary">
                <strong>{address.address_title}</strong>
              </div>
              <div className="row">
                {address.address_line1}
              </div>
              <div className="row">
                {address.address_line2}
              </div>
            </MoveDiv>
          )}
          {addresses.length <= 0 && "Aún no dispones de direcciones"}
        </div>
        {newAddress && <React.Fragment>
          <div style={{ fontSize: "20px" }} className="row justify-content-center text-center mb-3">
            NUEVA DIRECCIÓN
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="NOMBRE DE DIRECCIÓN *" type="text" name="address_title" value={address_title}
                       onChange={this.changeInfo} notEmpty={true}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="DIRECCIÓN *" type="text" name="address_line1" value={address_line1}
                       onChange={this.changeInfo} notEmpty={true}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="DIRECCIÓN (línea 2)" type="text" name="address_line2" value={address_line2}
                       onChange={this.changeInfo}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="CP *" type="text" name="pincode" value={pincode}
                       onChange={this.changeInfo} notEmpty={true}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="CIUDAD *" type="text" name="city" value={city}
                       onChange={this.changeInfo} notEmpty={true}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="row mb-1">PAÍS *</div>
              <Select
                name="userSelect"
                value={country}
                onChange={this.changeCountry}
                options={countries}
                className="rounded-0 row text-dark mb-3"
              />
            </div>
          </div>
          {country.value === countries[0].value && <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="row mb-1">PROVINCIA *</div>
              <Select
                name="userSelect"
                value={state}
                onChange={this.changeState}
                options={states}
                className="rounded-0 row text-dark mb-3"
              />
            </div>
          </div>}
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="TELÉFONO *" type="number" name="phone" value={phone} onChange={this.changeInfo}
                       captureErrors={this.captureErrors} maxChar={20} minChar={9} notEmpty={true}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="EMAIL *" type="email" name="email_id" value={email_id}
                       onChange={this.changeInfo} notEmpty={true} contain="@"/>
            </div>
          </div>
        </React.Fragment>}
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <div className="row bg-primary">
              <Button size="lg" block color="secondary" onClick={newAddress ? this.submit : this.getFormNewAddress}>
                {newAddress ? "GUARDAR" : "AÑADIR NUEVA DIRECCIÓN"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectAddress;
