import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { Col, Container, Row } from "reactstrap";

const MoveDiv = posed.div({
  exit: {
    opacity: 0
  },
  enter: {
    opacity: 1
  }
});

class Modal extends Component {

  state = {
    children: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.children !== prevProps.children){
      if(this.state.children === null){
        this.setState({children: this.props.children});
      } else if (this.props.children !== prevProps.children) {
        this.setState({children: null});
        setTimeout(() => this.setState({children: this.props.children}), 300)
      }
    }
  }


  render() {
    const { closeModal } = this.props;
    const { children } = this.state;
    return (
      <PoseGroup>
        {
          children !== null &&
          <MoveDiv style={{ zIndex: 4000 }} className="fixed-top" key="modal">
            <Container fluid onClick={closeModal}
                 style={{ zIndex: 4000, opacity: 0.8, backgroundColor: "rgba(255,255,255,0.24)" }}
                 className="fixed-top vh-100">
            </Container>
            <Container fluid style={{ top: 0 }} className="position-absolute vh-100">
              <Row className="h-100 center px-2">
                <Col xs={"auto"} style={{ zIndex: 4001, maxHeight: "90vh" }} className="position-fixed shadow overflow-auto">
                  <Row>
                    {children}
                  </Row>
                </Col>
              </Row>
            </Container>
          </MoveDiv>
        }
      </PoseGroup>
    );
  }
}

export default Modal;
