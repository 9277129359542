import React from "react";
import { Container, Row } from "reactstrap";
import DivImg from "../shared/decoration/DivImg";
import SocialMedia from "../shared/contact/SocialMedia";
import wall from "./../../media/walls/fallingCoffee.jpg"
import logo from "./../../media/logos/logo.png"

const Home = () => {
  return (
    <Container fluid>
      <Row>
        <DivImg img={wall} className="vh-100 w-100 center px-2" style={{ backgroundAttachment: "fixed" }}>
          <img className="w-100 p-5 bg-secondary-90 shadow-lg" style={{ maxWidth: "600px" }} src={logo} alt=""/>
        </DivImg>
        <SocialMedia position={{top: 100, left: 0}}/>
      </Row>
    </Container>
  );
}

export default Home;