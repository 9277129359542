import React, { useContext, useMemo } from "react";
import { StockContext } from "../wrappers/StockWrapper";
import productsCategories from "./shop/productsCategories";
import Loading from "../shared/loading/Loading";
import { Container, Row } from "reactstrap";
import Product from "../shared/product/Product";
import Banner from "../shared/decoration/Banner";

const Shop = ({ match }) => {
  const { category } = match.params;
  const { products } = useContext(StockContext)
  const actualCategory = productsCategories.find(({ pathParam }) => pathParam === category)


  const realProducts = useMemo(() => {
    if (products) {
      const groups = actualCategory.groups;
      if (groups === "others") {
        const allGroups = [];
        productsCategories.forEach((cat) => {
          if (Array.isArray(cat.groups)) cat.groups.forEach((group) => allGroups.push(group))
        })
        return products.filter(item => !allGroups.includes(item.item_group));
      }

      return products.filter(item => groups.includes(item.item_group));
    }
    // eslint-disable-next-line
  }, [category, products])

  
  console.log(products, realProducts)

  if (!realProducts) return <Loading/>

  const others = [];
  const groups = {};
  realProducts.filter(item => !item.ocult).forEach(item => {
    if (item.section) {
      if (!Array.isArray(groups[item.section])) {
        groups[item.section] = []
      }
      groups[item.section].push(item)
    } else {
      others.push(item)
    }
  });

  return (
    <Container fluid>
      <Row className="justify-content-center mb-5">
        <Banner title={actualCategory.title.toUpperCase()}/>
      </Row>
      {Object.keys(groups).map((name, key) =>
        <React.Fragment key={key}>
          <Row className="justify-content-center mb-4 pt-4 title-lg">
            {name === 'Especialidad' ? 'Coffees from the World' : name}
          </Row>
          <Row className="justify-content-center mb-4">
            {groups[name].map((item, key) =>
              <Product key={key} product={item} add={true}/>
            )}
          </Row>
        </React.Fragment>)}
      {others.length > 0 && <React.Fragment>
        <div className="row justify-content-center my-2 title-lg">
          Y mucho más
        </div>
        <div className="row justify-content-center">
          {others.map((item, key) =>
            <Product key={key} product={item} add={true}/>
          )}
        </div>
      </React.Fragment>}
    </Container>
  );
}

export default Shop;