import React, { PureComponent } from 'react';
import { toast } from "react-toastify";
import { login, signUp } from "../../../../api/apiRequest";
import { Button } from "reactstrap";
import MyInput from "../../../old/MyInput";

class LoginAndSignUp extends PureComponent {

  state = {
    usr: (localStorage.getItem("usr-arabay") ? localStorage.getItem("usr-arabay") : ""),
    pwd: (localStorage.getItem("pwd-arabay") ? localStorage.getItem("pwd-arabay") : ""),
    name: "",
    lastName: "",
    phone: "",
    signUp: false,
  };
  errors = [];

  changeInfo = (event) => this.setState({
    [event.target.name]: (event.target.value)
  });


  submit = (event) => {
    event.preventDefault();
    if (this.errors.length < 1) {
      const { signUp } = this.state;
      if (signUp) {
        this.register();
      } else {
        this.login()
      }
    } else {
      toast(("Nos sería más fácil identificarte si rellenas todos los datos correctamente"), {
        className: "bg-danger text-white",
        containerId: "store"
      });
    }
  };

  login = () => {
    const { usr, pwd } = this.state;
    login(usr, pwd)
      .then((res) => {
        toast(("Nos encanta tenerte de nuevo " + res.data.full_name), {
          className: "bg-success text-white",
          containerId: "store"
        });

        // this.props.cookies.set("sid", )
        localStorage.setItem("usr-arabay", usr);
        localStorage.setItem("pwd-arabay", pwd);

        // DECOMMEND THIS CODE WHEN LOGIN WERE POSSIBLE
        this.props.doLogin();
      })
      .catch(() => {
        toast(("¡Ups! Parece que no encontramos nungún usuario con ese email y esa contraseña"), {
          className: "bg-danger text-white",
          containerId: "store"
        });
      });
    // FAKE
    // this.props.doLogin();
  };

  register = () => {
    const { usr, pwd, name, lastName, phone } = this.state;
    signUp({ email: usr, first_name: name, last_name: lastName, password: pwd, phone: phone })
      .then((res) => {
        toast(("Bienvenido/a " + name), {
          className: "bg-success text-white",
          containerId: "store"
        });
        localStorage.setItem("usr-arabay", usr);
        localStorage.setItem("pwd-arabay", pwd);

        this.setState({ signUp: false })
      })
      .catch(() => {
        toast(("¡Ups! Hemos encontrado un error, vuelva a intentarlo más tarde"), {
          className: "bg-danger text-white",
          containerId: "store"
        });
      });
  };

  render() {
    const { usr, pwd, signUp, phone, lastName, name } = this.state;
    return (
      <form onSubmit={this.submit} className="container-fluid my-5">
        <div style={{ fontSize: "20px" }} className="row justify-content-center text-center mb-3">
          {signUp ? "REGISTRO" : "INICIAR SESIÓN"}
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <MyInput title="EMAIL *" type="email" name="usr" value={usr}
                     onChange={this.changeInfo} notEmpty={true} contain="@"/>
          </div>
        </div>

        {signUp && <React.Fragment>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="NOMBRE" type="text" name="name" value={name}
                       onChange={this.changeInfo} notEmpty={true}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="APELLIDOS" type="text" name="lastName" value={lastName}
                       onChange={this.changeInfo} notEmpty={true}/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <MyInput title="TELÉFONO" type="number" name="phone" value={phone} onChange={this.changeInfo}
                       maxChar={20} minChar={9} notEmpty={true}/>
            </div>
          </div>
        </React.Fragment>}
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <MyInput title="CONTRASEÑA *" type="password" name="pwd" value={pwd}
                     onChange={this.changeInfo} notEmpty={true}/>
          </div>
        </div>
        <div className="row justify-content-center">
          <div style={{ cursor: "pointer" }} onClick={() => this.setState({ signUp: !signUp })}>
            {signUp ? "Ya soy cliente" : "No soy cliente"}
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-12 col-md-6">
            <div className="row bg-primary">
              <Button size="lg" block color="secondary">
                {signUp ? "REGISTRO" : "INICIAR SESIÓN"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default LoginAndSignUp;
