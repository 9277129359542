import coffeeWall from "../../../media/walls/coffee.jpg";
import infusionWall from "../../../media/walls/infusion.jpg";
import cursosWall from "../../../media/walls/cursos.jpg";
import maquinasWall from "../../../media/walls/maquinas.jpg";
import accesoriosWall from "../../../media/walls/accesorios.jpg";

const productsCategories = [
  {
    title: "CAFÉ",
    image: coffeeWall,
    path: "/shop/coffee",
    pathParam: "coffee",
    groups: ["Albertí 250", "CAPSULAS", "BAY250", "ARABAY250", "BAY KG", "ARABAY"]
  },
  {
    title: "INFUSIONES",
    image: infusionWall,
    path: "/shop/infusion",
    pathParam: "infusion",
    groups: ["INFUSIONES", "PIRAMIDES B-ZIP"]
  },
  { title: "CURSOS", image: cursosWall, path: "/shop/school", pathParam: "school", groups: ["Formaciones"] },
  { title: "MÁQUINAS", image: maquinasWall, path: "/shop/machines", pathParam: "machines", groups: ["Maquinària"] },
  { title: "ACCESORIOS", image: accesoriosWall, path: "/shop/accessories", pathParam: "accessories", groups: "others" },
]

export default productsCategories;