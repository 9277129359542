import React, { useContext, useEffect, useMemo, useState } from "react";
import { StockContext } from "../wrappers/StockWrapper";
import { Button, Col, Container, Row } from "reactstrap";
import Loading from "../shared/loading/Loading";
import Banner from "../shared/decoration/Banner";
import { CartContext } from "../wrappers/CartWrapper";
import Remove from "../shared/product/shared/Remove";
import { toast } from "react-toastify";
import Add from "../shared/product/shared/Add";
import Select from "react-select";

const renderHTML = require('react-render-html');


const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const ProductPage = ({ match }) => {
  const { id } = match.params;
  const { products, findProduct } = useContext(StockContext)
  const {
    findCardProduct,
    addCardProduct,
    subtractCartProduct,
    numberOfCardProducts
  } = useContext(CartContext)

  const product = useMemo(() => {
    return findProduct(id);
    // eslint-disable-next-line
  }, [products, id])
  const [selectedProduct, setSelectedProduct] = useState({ value: null, label: null });

  useEffect(() => {
    if (product) {
      const existsSubProducts = (product.rel_items && product.rel_items.length > 0);
      const defaultProduct = (existsSubProducts ? { ...product.rel_items[0] } : { ...product })
      setSelectedProduct({ value: defaultProduct, label: (defaultProduct?.item_property || "") })
    }
  }, [product])

  const { foundProduct } = useMemo(() => {
    if (selectedProduct.value) return findCardProduct(selectedProduct.value.item_code)
    else return {};
    // eslint-disable-next-line
  }, [selectedProduct.value, numberOfCardProducts]);

  const quantity = (foundProduct || {}).quantity


  if (!product || !selectedProduct.value) return <Loading/>

  const notification = (text, opt) => {
    toast(text, { ...opt, containerId: "store" });
  };

  const addProduct = () => {
    !selectedProduct.value.quantity && notification("Se ha añadido " + selectedProduct.value.item_name + ": " + selectedProduct.label + " al carrito de compra", { className: "bg-success text-white" });
    addCardProduct(selectedProduct.value);
  };

  const subProduct = () => {
    notification("Se ha eliminado un producto de su carrito de compra: " + selectedProduct.value.item_name + ": " + selectedProduct.label, { className: "bg-danger text-white" });
    subtractCartProduct(selectedProduct.value.item_code)
  };

  let img;
  if (selectedProduct.value.thumbnail) {
    img = REACT_APP_API_URL + "image" + selectedProduct.value.thumbnail
  } else if (selectedProduct.value.website_image) {
    img = REACT_APP_API_URL + "image" + selectedProduct.value.website_image
  }

  const options = [];

  if (product.rel_items) {
    product.rel_items.forEach((item) => {
      if (item.item_property) {
        options.push({ value: item, label: item.item_property })
      }
    })
  }

  return (
    <Container fluid>
      <Row>
        <Banner title={selectedProduct.value.item_name}/>
      </Row>
      <Row className="justify-content-end align-items-center pr-3 pr-lg-5" style={{ transform: "translateY(-50%)" }}>
        {quantity && <Remove size="medium" onClick={subProduct} drop={false}/>}
        <div className="mx-2"/>
        <Add onClick={addProduct} num={quantity}/>
      </Row>

      <Row className="py-3">
        <Container>
          <Row className="justify-content-center">
            {img &&
            <Col xs="auto" className="my-3">
              <img width={300} src={img} alt=""/>
            </Col>
            }

            <Col>
              <Row className="title-lg text-center justify-content-center mb-3">
                {selectedProduct.value.item_property}
              </Row>
              <Row className="common-text force-text-white mb-3 text-justify px-3">
                {renderHTML(selectedProduct.value.web_long_description)}
              </Row>
              {options.length > 1 &&
              <Row className="justify-content-center">
                <div style={{ minWidth: "250px" }}>
                  <Select style={{ minWidth: "250px" }} classNamePrefix="select" options={options}
                          value={selectedProduct} onChange={(newValue) => setSelectedProduct(newValue)}/>
                </div>
                <div className="title-lg text-right d-none d-md-block ml-auto">
                  {selectedProduct.value.price.toFixed(2) + " €"}
                </div>
              </Row>
              }

              <Row className="center mt-5">
                <Button color="primary" size="lg" onClick={addProduct}>
                  Añadir al carrito ({selectedProduct.value.price.toFixed(2) + " €"})
                </Button>
              </Row>
            </Col>


          </Row>
        </Container>
      </Row>

    </Container>
  );
}

export default ProductPage;