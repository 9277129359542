import React from "react";
import ReactPlayer from "react-player";

const BackgroundVideo = ({ url, ...props}) => {

  return (
    <div className="position-relative h-100 w-100 overflow-hidden">
      <ReactPlayer
        url={url}
        className={"player position-absolute"}
        playing={true}
        controls={false}
        muted={true}
        loop={true}
        width="100%"
        height="100%"
        progressInterval={5000}
        {...props}
      />
    </div>
  )
}

export default BackgroundVideo;
