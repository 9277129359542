import React, { Component } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import * as L from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import placeholder from './../../../media/icons/placeholder.svg'

class MyMap extends Component {

  state = {
    zoom: 8,
  };

  componentDidMount() {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
  }

  render() {
    const center = [39.547856, 2.805966];

    const icon = L.icon({
      iconUrl: placeholder,
      iconSize: [40, 30],
    });

    return (
      <MapContainer center={center} zoom={this.state.zoom} className="w-100 h-100 bg-transparent" gestureHandling={true}>
        <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                   url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}?access_token=pk.eyJ1IjoicWNqMjg2MTciLCJhIjoiY2p0NWgxa2Z3MDV4ajQ0bXcxZzVsNHhyeiJ9.9Ur3i1ASnWO3f9D8p976-A"/>
        <Marker position={[39.5713798, 2.6538181]} icon={icon}>
          <Tooltip direction='top' opacity={0.8}>
            Carrer del Sindicat, 5 (Esquina con Plaza Mayor) - Palma
          </Tooltip>
        </Marker>
        <Marker position={[39.61793, 2.9751725]} icon={icon}>
          <Tooltip direction='top' opacity={0.8}>
            Finca ca'n Bielo s/n - Lloret de Vistalegre
          </Tooltip>
        </Marker>
        <Marker position={[38.909591, 1.427182]} icon={icon}>
          <Tooltip direction='top' opacity={0.8}>
            Carrer Castella, 30 - Ibiza
          </Tooltip>
        </Marker>
        <Marker position={[39.890524, 4.253246]} icon={icon}>
          <Tooltip direction='top' opacity={0.8}>
            Carretera Ciutadella, Menorca
          </Tooltip>
        </Marker>
        <Marker position={[39.573937, 2.653562]} icon={icon}>
          <Tooltip direction='top' opacity={0.8}>
            Mercado Olivar, Palma
          </Tooltip>
        </Marker>
      </MapContainer>
    );
  }
}

export default MyMap;
